import React from 'react';
import Subtitle from '../../shared/Subtitle'
import Code from '../../shared/Code'

export function JS({codeLang}) {

    switch(codeLang) {
        case 'http': return <Code lang="javascript">
        {`
// Agregando un Sticker (firmante) al documento enviado

Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /developers/json

//Enviando el documento a Firmamex
{
    b64_doc: {
        data: 'documento codificado en base64',
        name: 'contrato.pdf'
    }
}

// Agregando un Sticker (firmante) al documento enviado

Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /developers/json

{
    b64_doc: {
        data: 'documento codificado en base64',
        name: 'contrato.pdf'
    },
    stickers: [{
        "authority": "Vinculada a Correo Electronico por Liga",
        "stickerType": "rect",
        "dataType": "email",
        "imageType":"stroke",
        "data": "firmante@dominiodelfirmante.com",
        "page": 0,
        "rect": {
            "lx": 330,
            "ly": 300,
            "tx": 530,
            "ty": 400
        }
    }]
});

        `}
        </Code>
        case 'http': return <Code lang="javascript">
            {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /developers/json

//Enviando el documento a Firmamex
{
    b64_doc: 'documento codificado en base64',
    name: 'contrato.pdf'
}

// Agregando un Sticker (firmante) al documento enviado
{
    b64_doc: {
        data: 'documento codificado en base64',
        name: 'contrato.pdf'
    },
    stickers: [{
        "authority": "Vinculada a Correo Electronico por Liga",
        "stickerType": "rect",
        "dataType": "email",
        "imageType":"stroke",
        "data": "firmante@dominiodelfirmante.com",
        "page": 0,
        "rect": {
            "lx": 330,
            "ly": 300,
            "tx": 530,
            "ty": 400
        }
    }]
}

            `}
        </Code>
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        default: return <Code lang="javascript">
{`
// Enviando el documento a Firmamex

const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const response = await services.request({
    b64_doc: {
        data: 'documento codificado en base64',
        name: 'contrato.pdf'
    }
});


// Agregando un Sticker (firmante) al documento enviado

const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const response = await services.request({
    b64_doc: {
        data: 'documento codificado en base64',
        name: 'contrato.pdf'
    },
    stickers: [{
        "authority": "Vinculada a Correo Electronico por Liga",
        "stickerType": "rect",
        "dataType": "email",
        "imageType":"stroke",
        "data": "firmante@dominiodelfirmante.com",
        "page": 0,
        "rect": {
            "lx": 330,
            "ly": 300,
            "tx": 530,
            "ty": 400
        }
    }]
});

`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {
`
{
    "document_ticket":"c6279a7c-b3d5-4c71-b417-4687a4f38914",
    "document_flow":false,
    "sticker_count":1,
    "stickers":[
        {
            "sticker_id":"qv3ZzAL3w",
            "sticker_index":0,
            "sticker_email":"firmante@dominiodelfirmante.com",
            "sticker_data":"firmante@dominiodelfirmante.com",
            "sticker_page":0,
            "sticker_coordinates":{
                "lx":388.0,
                "ly":400.0,
                "ux":496.0,
                "uy":480.0
            },
            "sticker_type":"EMAIL",
            "sticker_image_type":"hash",
            "sticker_authorities":[
                {
                "authority_name":"Vinculada a Correo Electrónico"
                }
            ]
        }
    ],
    "document_url":"https://firmamex.com/pdf/c6279a7c-b3d5-4c71-b417-4687a4f38914/?child=true&inv=true"
}
`
        }
    </Code>
}

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Enviando el documento en base64</Subtitle>
            <div className="Content">
                <p>
                    Si tu documento no es accesible por una URL, puedes mandarlo a Firmamex codificado en base64. Es muy similar a enviar la URL del documento, solo que el object key cambia de 
                    nombre de url_doc a b64_doc, y en lugar de enviar la URL, mandas la información del documento codificado en base64. 
                </p>
                <br />
                <p>
                    Este ejemplo, es igual que el ejemplo anterior, con la única diferencia de que enviará el documento codificado en base64.
                </p>
                <br />
                </div>
                
        </React.Fragment>
    )
}

export default { JS, html, Response }