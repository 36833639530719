import React from 'react';
import Header from '../../shared/Header'
import Code from '../../shared/Code'
import {Link} from 'react-router-dom'

export function JS({codeLang}) {

    switch(codeLang) {
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        default: return <Code lang="javascript">
{`
// Enviando el documento a Firmamex

const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const response = await services.request({
    b64_doc: {
        url: 'documento codificado en base64',
        name: 'contrato.pdf'
    }
});


// Agregando un Sticker (firmante) al documento enviado

const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const response = await services.request({
    b64_doc: {
        data: 'documento codificado en base64',
        name: 'contrato.pdf'
    },
    stickers: [{
        "authority": "Vinculada a Correo Electronico por Liga",
        "stickerType": "rect",
        "dataType": "email",
        "imageType":"stroke",
        "data": "firmante@dominiodelfirmante.com",
        "page": 0,
        "rect": {
            "lx": 330,
            "ly": 300,
            "tx": 530,
            "ty": 400
        }
    }]
});

`}
        </Code>
    }
}

export function html() {
    return (
        <React.Fragment>
            <Header>Acciones para Documento</Header>
            <div className="Content">
                <p>
                    Una vez que ya diste de alta un documento, puedes realizar las siguientes acciones:
                </p>
                <ul>
                    <li><Link to="/docs/acciones/endosos">Endosar documento</Link></li>
                    <li><Link to="/docs/acciones/descargar">Descargar documento</Link></li>
                    <li><Link to="/docs/acciones/flujo">Flujo</Link></li>
                    <li><Link to="/docs/acciones/flujoExpedientes">Flujo expedientes</Link></li>
                    <li><Link to="/docs/acciones/editarFirmantes">Editar firmantes</Link></li>
                    <li><Link to="/docs/acciones/info">Pedir información de Documento</Link></li>
                    <li><Link to="/docs/acciones/eliminar">Eliminar documento</Link></li>
                    <li><Link to="/docs/acciones/restaurar">Restaurar documento</Link></li>
                    <li><Link to="/docs/acciones/descargarSelloTiempo">Descargar sello de tiempo</Link></li>
                    <li><Link to="/docs/acciones/descargarCorreoCertificado">Descargar información de correo
                        certificado</Link></li>
                </ul>
            </div>
        </React.Fragment>
    )
}

export default {JS, html}
