import React, { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import {Collapse} from 'reactstrap';

export default function MenuItem({name, path, items}) {

    const [showItems, setShowItems] = useState(false);
    const URL = window.location.href
    const realPath = window.location.pathname

    useEffect(() => {
        if(URL.includes(path)) {
            setShowItems(true)
        }
    }, [URL, path])

    function isPath() {
        if (realPath === '/docs') {
            if (path === '/') {
                return true;
            } else {
                return false;
            }
        } else {
            if (realPath === '/docs' + path) {
                return true;
            } else {
                return false;
            }
        }
    }

    return (
        <div>
            {
                !items || items.length === 0 ?
                <div className="MenuItem">

                    <Link to={`/docs${path}`}>
                        <div style={((isPath()) ? {fontWeight: "600", color: '#0B66C9'} : null)}>{name}</div>
                    </Link>
                </div> :
                <div className="MenuItem">
                    <Link to={`/docs${path}`} onClick={() => setShowItems(!showItems)}>
                        <div style={(URL.includes(path) ? {fontWeight: "600", color: '#0B66C9'} : null)}>{name}</div>
                    </Link>
                    <Collapse isOpen={showItems}>
                        {
                            items.map((m,i) => (
                                <MenuItem 
                                    key={i}
                                    path={m.path}
                                    items={m.items}
                                    name={m.name}></MenuItem>
                            ))
                        }
                    </Collapse>
                </div>
            }            
        </div>
    )
}