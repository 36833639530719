import React from 'react';
import Subtitle from '../../shared/Subtitle'
import Code from '../../shared/Code'
import CodeSection from '../../shared/CodeSection';
import BlueSection from '../../shared/BlueSection';

export function JS({codeLang}) {

    switch(codeLang) {
        case 'http': return <Code lang="javascript">
                {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
GET /api/{ticket}/signer/{stickerId}/update

{
    authority: 'Vinculada a Correo Electronico por Liga',
    email: 'email@firmamex.com',
    data: 'email@firmamex.com',
    imageType: 'desc',
    // updateAll: true
}
                `}
                </Code>
        default: return <Code lang="javascript">
{`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const frmxId = '';
const stickerId = '';

const responseSticker = await services.updateSigner(frmxId, stickerId, {
    authority: 'Vinculada a Correo Electronico por Liga',
    email: 'email@firmamex.com',
    data: 'email@firmamex.com',
    imageType: 'desc',
    // updateAll: true
})

`}
        </Code>
    }
}


export function Response() {
    return <Code lang="javascript">
        {
            `{
    status: "success",
}
`
        }
    </Code>
}

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Editar firmantes</Subtitle>
            <div className="Content">
                <p>
                    Mientras no hayan realizado su firma, puedes editar la siguiente información de los firmantes en un documento:
                </p>
                <ul>
                    <li>Dato de verificación para firma</li>
                    <li>Autoridad de firma</li>
                    <li>Correo</li>                    
                    <li>Tipo de imagen de firma</li>                 
                </ul>
                <p>
                    Para editar esta información, requieres 2 datos: el firmamexId o document_ticket del documento y el sticker_id del firmante, estos datos los obtienes al dar de alta el documento:
                </p>
                <CodeSection lang="js" height={'400px'} label="Ejemplo">
                    {
`
{
    // id unico del documento
    document_ticket: 'afc7d634dbaeb-4e1d-b56a-b0b4e293f126',
    sticker_count: 1,
    stickers: [
        {
        // id unico para el sticker
        sticker_id: '2Vxy55zxo',
        sticker_index: 0,
        sticker_email: null,
        sticker_data: '526624123456',
        sticker_page: 0,
        sticker_coordinates: [Object],
        sticker_type: 'PHONE',
        sticker_image_type: 'desc',
        sticker_authorities: [Array]
        }
    ],
    document_url: 'https://firmamex.com/pdf/afc7d634dbaeb-4e1d-b56a-b0b4e293f126/?child=true&inv=true'
}
`
                    }
                </CodeSection>
                <br/>
                <p>
                    Adicional a los datos que quieres actualizar del firmante, puedes especificar la opción de <strong>"updateAll"</strong> para actualizar todos los stickers de ese firmante.                     
                </p>
                <BlueSection>
                    Si el documento es parte de un expediente, al especificar la opción de <strong>updateAll</strong> se actualizarán todos los stickers del firmante en el expediente. Si el firmante no es uno de los firmantes actuales en el expediente y no se usa esta opción, se agregará como el último para firmar
                </BlueSection>
            </div>
          </React.Fragment>
    )
}

export default { JS, html, Response }