import React from 'react';
import Subtitle from '../../shared/Subtitle'
import Code from '../../shared/Code'
import CodeSection from '../../shared/CodeSection';

export function JS({codeLang}) {

    switch(codeLang) {
        case 'http': return <Code lang="javascript">
                {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json

// Obtenemos los sellos de un documento
GET /api/document/{frmxId}/stamps

// Obtenemos el documento correspondiente al sello
GET /api/document/{frmxId}/stamp/{stampHash}/pdf

                `}
                </Code>
        default: return <Code lang="javascript">
{`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const frmxId = '';

// Obtenemos los sellos de un documento
const stamps = await services.getNom151StampsForDocument(frmxId);

// Obtenemos el documento correspondiente al sello
const document = await services.getDocumentForStamp(frmxId, stamps[0].hash)


`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {
            `
// Obtenemos los sellos de un documento
{
    "timestamps": [
        {
            "hash": "ce92da77b45ddbb26ab4210600023de8f0effb062276cd4c2765feed3988cfc4",
            "tsa": "L=MIGUEL HIDALGO,ST=CIUDAD DE MEXICO,C=MX,CN=CECOBAN-PSC-NOM151-1-01,OU=Thales TSS ESN:995D-C54C-D22E,O=CECOBAN SA DE CV",
            "genTime": "2023-07-10T18:21:28.467Z",
            "status": "granted",
            "timestamp": "MIISvDADAgEAMIISswYJKoZ...",
            "policy": "2.16.484.101.10.316.2.3.1.2.1.1.1"
        }
    ]
}

// Obtenemos el documento correspondiente al sello
{
    "name": "Ejemplo",
    "data": "JVBERi0xLjQNJeLjz9MNCjIwNS..." 
}
            `
        }
    </Code>
}

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Descargar sello de tiempo</Subtitle>
            <div className="Content">
                <p>
                    Firmamex incrusta los sellos de tiempo NOM 151 dentro del documento utilizando una firma adicional. Pero si lo requieres, puedes descargar el sello y el archivo por separado
                </p>
                <p>
                    El primer paso es obtener los sellos de tiempo del documento:
                </p>
                <CodeSection lang="js">
                {
`
// Solicitud
const stamps = await services.getNom151StampsForDocument(frmxId);
`
                }
                </CodeSection>
                <CodeSection lang="js">
                {
`
// Respuesta
{
    // Hay ocasiones en las que un documento puede contener más de un sello, 
    // por lo que se regresa un arreglo
    timestamps: [
      {
        hash: '8dfe6da51f8bcb1e02b1f9082bdaea2c62963d3892c0f8c9ff2f85a027536ea8',
        tsa: 'L=MIGUEL HIDALGO,ST=CIUDAD DE MEXICO,C=MX,CN=CECOBAN-PSC-NOM151-1-01,OU=Thales TSS ESN:995D-C54C-D22E,O=CECOBAN SA DE CV',
        genTime: '2022-07-12T01:56:18.991Z',
        status: 'granted',
        // Sello en base64
        timestamp: 'MIISujADAg...',
        policy: '2.16.484.101.10.316.2.3.1.2.1.1.1'
      }
    ]
  }
`
                }
                </CodeSection>
                <br/>
                <p>
                    Después de obtener el sello de tiempo, se tiene que solicitar el documento correspondiente al hash del sello deseado
                </p>
                <CodeSection lang="js">
                {
`
// Solicitud
const response = await services.getDocumentForStamp(frmxId, stampHash)
`
                }
                </CodeSection>
                <CodeSection lang="js">
                {
`
// Respuesta
{
    name: 'Contrato ejemplo',
    // Documento en base64
    data: 'JVBER...'
}
`
                }
                </CodeSection>
            </div>
          </React.Fragment>
    )
}

export default { JS, html, Response }