import React from 'react';
import Header from '../../shared/Header'
import Code from '../../shared/Code'
import CodeSection from '../../shared/CodeSection'

export function JS({codeLang}) {

    switch(codeLang) {
        case 'http': return <Code lang="javascript">
                {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /developers/json

// Dando de alta un template HTML

{ 
    template_type: 'html',
    template_data: Buffer.from('html del documento').toString('base64'),
    template_name: 'ejemplo1' 
}

// Una vez registrado el template solo manda los datos
// cada vez que lo necesites

{
    template: {
        title: 'ejemplo1',
        name: 'nombre para este documento'
    },
    fields: [
        {
            "id": "full-name",
            "value": "John Doe"
        }
    ],
    tables: [
        {
            id: 'directory',
            rows: [
                'John Doe',
                '202-555-0141',
                '843 James Lane Lake Mary, FL 32756'
            ]
        }
    ]
}
                `}
                </Code>
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        default: return <Code lang="javascript">
{`

const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

// Dando de alta un template HTML

const templateData = 'html del documento';
const response = await services.saveTemplate({ 
    template_type: 'html',
    template_data: Buffer.from(templateData).toString('base64'),
    template_name: 'ejemplo1' 
})

// Una vez registrado el template solo manda los datos
// cada vez que lo necesites

const response = await services.request({
    template: {
        title: 'ejemplo1',
        name: 'nombre para este documento'
    },
    fields: [
        {
            "id": "full-name",
            "value": "John Doe"
        }
    ],
    tables: [
        {
            id: 'directory',
            rows: [
                'John Doe',
                '202-555-0141',
                '843 James Lane Lake Mary, FL 32756'
            ]
        }
    ]
})
`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {`
// Respuesta de Firmamex con campos dinámicos encontrados
{
    tables: [ { id: 'directory', rows: [] } ], 
    template_title: 'ejemplo1',
    fields: [
        { id: 'full-name', value: '' } 
    ]
}
        `}
    </Code>
}


export function html() {
    return(
        <React.Fragment>
            <Header>Templates HTML</Header>
            <div className="Content">
            <p>
                Para dar de alta el template, el primer paso es definir el HTML con su CSS inline.
                El HTML puede contener campos y tablas dinámicos para inyectar los datos más adelante.
            </p>
            <p>
                Un campo puede ser cualquier elemento HTML que contenga texto. Para declarar un campo en 
                tu página HTML agrega la clase “signmage-template-field” y un ID único para el elemento.
            </p>
                <CodeSection lang="html">
                {`
                    <p style="text-align:justify;">
                        <span class="signmage-template-field" id="full-name">
                            (Aquí irá tu valor especificado)
                        </span> 
                        es el dueño de este documento.
                    </p>`}
                </CodeSection>
            <p>
                Cuando generes el documento, el valor que especifiques será insertado en el campo.
            </p>
            <p>
                Las tablas son tablas HTML con la clase “signmage-template-table” y un ID único.
            </p>
            <p>
                De igual manera que con los campos, cuando el documento sea generado, los valores 
                que especifiques serán insertados en su lugar correspondiente.
            </p>
            <CodeSection lang="html">
                {
                    `
                    <table class="signmage-template-table" id="directory" style="border: 1px solid black;">
                    <theader>
                        <tr style="height: 31px;">
                            <th>
                                <strong>NAME</strong>
                            </th> 
                            <th>
                                <strong>PHONE</strong>
                            </th>
                            <th>
                                <strong>ADDRESS</strong>
                            </th> 
                        </tr>
                    </theader>
                    <tbody>
                    </tbody>
                </table>
                    `
                }
            </CodeSection>
            <p>
                Una vez que ya tienes el template definido en HTML, el siguiente paso es darlo de alta en Firmamex.
            </p>
            <p>
                <strong>Importante:</strong> El archivo HTML se tiene que enviar a Firmamex codificado en base64.
            </p>
            <p>
                Firmamex contestará con los campos dinámicos que encontró en tu template. De ahí en adelante, 
                cada vez que quieras utilizarlo, sólo es necesario que mandes los datos correspondientes.
            </p>
            </div>
        </React.Fragment>
    )
}
export default { JS, html, Response }