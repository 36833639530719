import React from 'react';
import Subtitle from '../../shared/Subtitle'
import Code from '../../shared/Code'

export function JS({codeLang}) {

    switch(codeLang) {
        case 'http': return <Code lang="javascript">
                {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /developers/docs

{
    ticket: '7481782e-391a-445d-995c-5e759290ad54', 
    action: 'restore'
}
                `}
                </Code>
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        default: return <Code lang="javascript">
{`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const response = await services.docs({
    ticket: '7481782e-391a-445d-995c-5e759290ad54', 
    action: 'delete'
});

`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {
            `
// Si el documento se pudo eliminar exitosamente
{
    "status":"success"
}

// Si el documento no se pudo eliminar
{
    "status":"error"                    
}
            `
        }
    </Code>
}

export function html() {
    return (
        <React.Fragment>            
            <Subtitle>Eliminar documento</Subtitle>
            <div className="Content">
                <p>
                    Puedes eliminar documentos que tu hayas subido y que no hayan sido firmados. 
                    Para hacer esto, envía el firmamexId del documento y la acción “delete”:
                </p>
            </div>
        </React.Fragment>
    )
}

export default { JS, html, Response }