import React from 'react';
import Header from '../../shared/Header'
import Code from '../../shared/Code'
import CodeSection from '../../shared/CodeSection'

export function JS({codeLang}) {

    switch(codeLang) {
        case 'http': return <Code lang="javascript">
                {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /developers/template/save

{ 
    template_type: 'pdfmake',
    template_data: {
        content: [
            'Este es un ejemplo de un template con dos campos dinámicos: nombre y fecha',
            'En este renglón se inyectará el nombre: {{nombre}}',
            'Y la fecha aquí: {{fecha}}'
        ]
    }
    template_name: 'ejemplo1' 
}

// Una vez registrado el template, solo manda los datos
// cada vez que lo necesites

{
    template: {
        title: 'ejemplo1',
        name: 'nombre para este documento'
    },
    fields:{
        nombre:"nombre dinamico",
        fecha:"fecha dinamica"
     }
}
                `}
                </Code>
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        default: return <Code lang="javascript">
{`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

// Dando de alta un template pdfmake

const response = await services.saveTemplate({ 
    template_type: 'pdfmake',
    template_data: {
        content: [
            'Este es un ejemplo de un template con dos campos dinámicos: nombre y fecha',
            'En este renglón se inyectará el nombre: {{nombre}}',
            'Y la fecha aquí: {{fecha}}'
        ]
    }
    template_name: 'ejemplo1' 
})

// Una vez registrado el template, solo manda los datos
// cada vez que lo necesites

const response = await services.request({
    template: {
        title: 'ejemplo1',
        name: 'nombre para este documento'
    },
    fields:{
        nombre:"nombre dinamico",
        fecha:"fecha dinamica"
     }
})
`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {`
// Respuesta de Firmamex con campos dinámicos encontrados

{
    "template_title":"make1",
    "fields":{
       "nombre":"",
       "fecha":""
    }
 }
        `}
    </Code>
}

export function html() {
    return(
        <React.Fragment>
            <Header>Templates pdfmake</Header>
            <div className="Content">
            <p>
                <a href="http://pdfmake.org" target="_blank" without rel="noopener noreferrer">pdfmake</a> es una herramienta de generación de PDF en javascript. Permite generar documentos complejos de manera sencilla.
            </p>
            <p>
                Para dar de alta el template, el primer paso es definir el documento siguiendo las instrucciones de pdfmake. Ofrecen un playground donde puedes ver el documento resultante dentro del navegador a medida que lo vas construyendo: <a href="http://pdfmake.org/playground.html">http://pdfmake.org/playground.html</a>
            </p>
            <p>
                Para definir las variables que quieres que inyecte Firmamex dentro del template, debes usar {`{{ nombre_variable }}`} donde nombre_variable será el nombre del campo:
            </p>
                <CodeSection lang="JS">
                {`
                    {
                        content: [
                            'Este es un ejemplo de un template con dos campos dinámicos: nombre y fecha',
                            'En este renglón se inyectará el nombre: {{nombre}}',
                            'Y la fecha aquí: {{fecha}}'
                        ]
                    }`}
                </CodeSection>
            
            <p>
                Una vez que ya tienes el template definido, el siguiente paso es darlo de alta en Firmamex.
            </p>
            <p>
                Firmamex contestará con las variables que encontró en tu template. De ahí en adelante, 
                cada vez que quieras utilizarlo, sólo es necesario que mandes los datos correspondientes.
            </p>
            </div>
        </React.Fragment>
    )
}
export default { JS, html, Response }