import React from 'react';
import Header from '../../shared/Header'
import CodeSection from '../../shared/CodeSection';
import BlueSection from '../../shared/BlueSection';
import Code from '../../shared/Code';

export function JS({ codeLang }) {

    switch (codeLang) {
        case 'http': return <Code lang="javascript">
                {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /developers/template/save

// Generando un proceso de correo certificado

{
    // en este ejemplo se está usando un template, 
    // pero también puedes proporcionar el documento 
    // por cualquiera de las demás opciones disponibles
    "template":{
       "title":"ejemplo1",
       "name":"Envío de documentación"
    },
    "fields":{
       "email":"john@email.com",
       "name":"John Doe"
    },
    "certifiedEmail":{
       "emailParams":[
          {
             "name":"nombre",
             "value":"John Doe"
          }
       ]
    },
    "attachments":[
       {
          "data":"JVBERi...",
          "name":"archivo 1.pdf",
          "mimeType":"application/pdf",
          "password":"contrasena"
       },
       {
          "data":"JVBERi...",
          "name":"archivo 2.pdf",
          "mimeType":"application/pdf"
       }
    ],
    // puedes indicar a los firmantes por medio de recuadros de firma: 
    // https://devs.firmamex.com/docs/documento/stickers/recuadro
    // o directamente por el API:
    "stickers":[
       {
          "authority":"Vinculada a Correo Electronico por Liga",
          "stickerType":"line",
          "dataType":"email",
          "imageType":"desc",
          "data":"john@email.com",
          "email":"john@email.com",
          "page":0,
          "rect":{
             "lx":355,
             "ly":102,
             "tx":555,
             "ty":202
          }
       }
    ],
    // configuración del flujo
    "workflow":{
       "remind_every":"1d",
       "language":"es",
       "attach_files":"true"
    }
 }
                `}
                </Code>
        default: return <Code lang="javascript">
            {`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

// Generando un proceso de correo certificado

const response = await services.request(
    {
        // en este ejemplo se está usando un template, 
        // pero también puedes proporcionar el documento 
        // por cualquiera de las demás opciones disponibles
        "template":{
           "title":"ejemplo1",
           "name":"Envío de documentación"
        },
        "fields":{
           "email":"john@email.com",
           "name":"John Doe"
        },
        "certifiedEmail":{
           "emailParams":[
              {
                 "name":"nombre",
                 "value":"John Doe"
              }
           ]
        },
        "attachments":[
           {
              "data":"JVBERi...",
              "name":"archivo 1.pdf",
              "mimeType":"application/pdf",
              "password":"contrasena"
           },
           {
              "data":"JVBERi...",
              "name":"archivo 2.pdf",
              "mimeType":"application/pdf"
           }
        ],
        // puedes indicar a los firmantes por medio de recuadros de firma: 
        // https://devs.firmamex.com/docs/documento/stickers/recuadro
        // o directamente por el API:
        "stickers":[
           {
              "authority":"Vinculada a Correo Electronico por Liga",
              "stickerType":"line",
              "dataType":"email",
              "imageType":"desc",
              "data":"john@email.com",
              "email":"john@email.com",
              "page":0,
              "rect":{
                 "lx":355,
                 "ly":102,
                 "tx":555,
                 "ty":202
              }
           }
        ],
        // configuración del flujo
        "workflow":{
           "remind_every":"1d",
           "language":"es",
           "attach_files":"true"
        }
     }
)
`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {`
        `}
    </Code>
}


export function html() {
    return (
        <React.Fragment>
            <Header>Correos certificados</Header>
            <div className="Content">
                <p>
                    Un proceso de correo certificado manda un documento a firma y le da seguimiento al proceso:
                </p>
                <p>
                    <ol>
                        <li>
                            Guarda los eventos de:
                            <ul>                                
                                <li>DOCUMENT_CREATED: Documento generado</li>
                                <li>REJECTED_DOCUMENT: Documento rechazado</li>
                                <li>DOCUMENT_COMPLETED: Documento completado</li>
                                <li>DOCUMENT_SIGNED: Documento firmado</li>
                                <li>DOCUMENT_STAMPED: Documento sellado</li>                                
                                <li>DOCUMENT_FLATTENED: Documento aplanado (sólo acroformas)</li>
                                <li>FLOW_STARTED: Flujo iniciado</li>
                                <li>SIGNATURE_INVITATION: Invitación a firma enviada</li>
                                <li>NON_SIGNER_RECIPENT_FLOW_STARTED: Notificacion de documento enviada a destinatario no firmante</li>
                                <li>FLOW_EXPIRED: Flujo expirado</li>
                                <li>EMAIL_DELIVERED: Correo entregado a servidor destinatario</li>
                                <li>EMAIL_CLICKED: Destinatario dio click en correo</li>
                                <li>EMAIL_OPENED: Destnatario abrió correo</li>
                                <li>EMAIL_FAILED: Correo no se pudo entregar</li>
                                <li>EMAIL_COMPLAINED: Destinatario marcó el correo como spam</li>
                            </ul>
                        </li>
                        <li>
                            Si el documento enviado contiene adjuntos, le permite al firmante descargar los archivos después de firmar el documento
                        </li>
                        <li>
                            Cuando se firma el documento o expira el flujo (lo que suceda primero), se recopila la información y se envía al firmante, al iniciador y a los involucrados en el documento. Esta notificación incluye:
                            <ul>
                                <li> EMLs de correos enviados </li>
                                <li> JSON con eventos generados </li>
                                <ul>
                                    Arreglo de eventos con los siguientes atributos:
                                    <li>documentEventType: Tipo de evento (ver punto 1)</li>
                                    <li>jsonMetaData: información adicional dependiendo del tipo de evento</li>
                                    <li>eventDate: fecha de generación del evento</li>
                                    <li>createdAt: fecha de guardado del evento</li>                                    
                                </ul>
                                <li> PDF con eventos generados. Contiene los mismos eventos que el JSON, pero en un documento PDF formateado </li>                                
                                <li> Documento enviado </li>
                                <li> Sello de tiempo NOM151 generado con la información anterior </li>
                            </ul>
                        </li>

                    </ol>
                </p>
                <BlueSection>
                    <b>Restricciones</b>
                    <br />
                    <ul>
                        <li>
                            El documento tiene que ser parte de un <a href='/docs/documento/flujos' target='_blank'>flujo</a>
                        </li>
                        <li>
                            El servicio, los correos del proceso y sus parámetros, son previamente configurados por Firmamex para tus credenciales
                        </li>
                        <li>
                            El tamaño total del archivo (documento a firmar + attachments) no puede superar los 20MB
                        </li>
                    </ul>
                </BlueSection>
                <p>
                    Para generar un proceso de correo certificado, debes incluir el atributo "certifiedEmail" en tu solicitud con los parámetros a inyectar en los correos del proceso:
                </p>
                <CodeSection lang="js">
                    {
                        `
                    "certifiedEmail":{
                        "emailParams":[
                           {
                              "name":"nombre",
                              "value":"John Doe"
                           }
                        ]
                     }
                    `
                    }
                </CodeSection>
                <p>
                    Y para adjuntar los documentos a entregar, debes incluir el atributo "attachments"
                </p>
                <CodeSection lang="js">
                    {
                        `
                    "attachments":[
                        {
                           "data":"base64 del archivo...",
                           "name":"archivo 1.pdf",
                           "mimeType":"application/pdf",
                           // password es opcional y sólo aplica si el documento es PDF, 
                           // si se incluye, se protegerá al documento con la contraseña 
                           // proporcionada
                           "password":"contrasena"
                        },
                        {
                           "data":"base64 del archivo...",
                           "name":"archivo 2.pdf",
                           "mimeType":"application/pdf"
                        }
                     ]
                    `
                    }
                </CodeSection>
                <br/>
                <p>
                    Una vez que detectes que el proceso ha sido completado, ya sea por que se firmó por completo el documento o porque expiró su flujo, puedes <a href="/docs/acciones/descargarCorreoCertificado" target="_blank">descargar la información del correo certificado</a>
                </p>
            </div>
        </React.Fragment>
    )
}
export default { html, JS, Response }