import React from 'react';
import Subtitle from '../../shared/Subtitle'
import Code from '../../shared/Code'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import ExternalLink from "../../shared/ExternalLink";

export function JS({ codeLang }) {

    switch (codeLang) {
        case 'http': return <Code lang="javascript">
                {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json

POST /api/{firmamexId}/workflow
{
    expiration_date: '25/11/2021',
}

POST /api/{firmamexId}/workflow
{
    ordered: false
}

POST /api/{firmamexId}/workflow
{
    cancel: true
}
                `}
                </Code>
        case 'python': return <Code lang="javascript">
            {`
from signmage import SignmageServices

webid = ""
apikey = ""

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
        </Code>
        default: return <Code lang="javascript">
            {`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

// edita la fecha de expiración de un flujo existente
const response = await services.workflow('04c0c65d-a06c-4f43-9a17-63b375b0106b', {
    expiration_date: '25/11/2021',
})

// cancela el orden de un flujo existente
const response = await services.workflow('04c0c65d-a06c-4f43-9a17-63b375b0106b', {
    ordered: false
})

// cancela el flujo
const response = await services.workflow('04c0c65d-a06c-4f43-9a17-63b375b0106b', {
    cancel: true
})

`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {
            `
// Respuesta exitosa
{
    status: "success",
}

// Error en la solicitud
{
    status: "error",
    error: "descripcion del error"
}
            `
        }
    </Code>
}

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Flujo</Subtitle>
            <div className="Content">
                <p>
                    Si expiró el flujo de un documento o si requieres hacer un cambio en las notificaciones, en el orden,
                    cancelar, hacer que se envíe de nuevo o crear un flujo para un documento existente,
                    puedes enviar la configuración del flujo para el documento.
                </p>
                <p>
                    <strong>Si el documento ya tiene flujo</strong>, se sobreescriben únicamente las opciones del flujo que se proporcionen.
                </p>
                <p>
                    <strong>Si el documento no tiene flujo</strong>, se crea un flujo con las opciones del flujo que se proporcionen, y al igual que
                    cuando se crea el flujo desde que se da de alta el documento (request), <strong>se utilizan los valores default para las opciones que no
                    se proporcionen</strong>
                </p>
                <p>
                    Al recrear el flujo, se reinicia el estado de las notificaciones, por lo que se enviarán nuevamente a aquellos
                    que no han firmado según la nueva configuración.
                </p>
                <p>
                    Además de <ExternalLink href="/docs/documento/flujos">los atributos del flujo</ExternalLink>,
                    puedes utilizar los siguientes atributos opcionales:
                </p>
                <ul>
                    <li>
                        <strong>cancel: true o false</strong> Indica si se debe cancelar el flujo del documento
                    </li>
                    <li><strong>ordered: false</strong> Permite cancelar el orden en el flujo del documento</li>
                </ul>
                <br/>
                <br/>
            </div>
        </React.Fragment>
    )
}

export default { JS, html, Response }
