import React from 'react';
import Subtitle from '../../shared/Subtitle'
import Code from '../../shared/Code'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

export function JS({ codeLang }) {

    switch (codeLang) {
        case 'http': return <Code lang="javascript">
                {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json

POST /api/{documentSet}/workflowSet
{
    expiration_date: '25/11/2021',
}

POST /api/{documentSet}/workflowSet
{
    cancel: true
}
                `}
                </Code>
        default: return <Code lang="javascript">
            {`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

// edita la fecha de expiración de un flujo
const response = await services.workflowSet('04c0c65d-a06c-4f43-9a17-63b375b0106b', {
    expiration_date: '25/11/2021',
})

// cancela el flujo
const response = await services.workflowSet('04c0c65d-a06c-4f43-9a17-63b375b0106b', {
    cancel: true
})

`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {
            `
// Respuesta exitosa
{
    status: "success",
}

// Error en la solicitud
{
    status: "error",
    error: "descripcion del error"
}
            `
        }
    </Code>
}

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Flujo expedientes</Subtitle>
            <div className="Content">
                <p>
                    Si expiró el flujo de un expediente o si requieres cancelar o hacer que se envíe de nuevo,
                    puedes enviar la configuración del flujo para el expediente. Las opciones que puedes proporcionar son:
                </p>
                <ul>
                    <li>
                        <strong>alert_all:</strong> Notificar a todos cuando se complete el flujo
                    </li>
                    <li>
                        <strong>expiration_date:</strong> Fecha de expiración del flujo de firma.
                    </li>
                    <li><strong>remind_every:</strong> Define que tan seguido se debe recordar a los firmantes.</li>
                    <li><strong>language:</strong> Lenguaje con el que se notificará a los firmantes.</li>
                    <li><strong>attach_files:</strong> Adjuntar documentos</li>
                    <li><strong>subject:</strong> Asunto</li>
                    <li><strong>message:</strong> Mensaje personalizado</li>
                </ul>
                <p>
                    Se sobreescriben únicamente las opciones del flujo que se proporcionen.
                </p>
                <p>
                    Al recrear el flujo, se reinicia el estado de las notificaciones, por lo que se enviarán nuevamente a aquellos
                    que no han firmado según la nueva configuración.
                </p>
                <p>
                    Además de los atributos del flujo, puedes utilizar la opción de "cancel" para cancelar el flujo del expediente:
                </p>
                <br />
                <br />
            </div>
        </React.Fragment>
    )
}

export default { JS, html, Response }