import React from 'react';
import Header from '../../shared/Header'
import {Link} from 'react-router-dom'

export function html() {
    return(
        <React.Fragment>
            <Header>Stickers</Header>
            <div className="Content">
            <p>
                Los stickers te permiten especificar quién puede firmar el documento y dónde puede hacerlo. 
                Sólo los usuarios que se especifiquen en los stickers podrán firmar el documento, y sólo pueden 
                firmar en la posición del sticker que hayas definido.
            </p>
            <p>
                Hay 3 formas diferentes de agregar un Sticker:
            </p>
            <ul>
                <Link to="/docs/documento/stickers/api"><li>A través del API</li></Link>
                <Link to="/docs/documento/stickers/recuadro"><li>Colocando un recuadro de firma</li></Link>
                <Link to="/docs/documento/stickers/lineasfirma"><li>Líneas de firma</li></Link>
            </ul>
            </div>
        </React.Fragment>
    )
}
export default {html}