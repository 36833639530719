import React from 'react';
import Subtitle from '../../shared/Subtitle'
import Code from '../../shared/Code'

export function JS({codeLang}) {

    switch(codeLang) {
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        default: return <Code lang="javascript">
{`
// Ejemplo de respuesta del API

{
   "document_ticket":"6c46b879-42e1-4b3e-a1c4-dcf959178821",
   "document_inflow":true,
   "document_flow":{
      "flow_expiration":"2023-07-21 16:19:51.376",
      "flow_language":"es"
   },
   "sticker_count":1,
   "stickers":[
      {
         "sticker_id":"4PQWbAyxM",
         "sticker_index":0,
         "sticker_email":"fernando@firmamex.com",
         "sticker_data":"fernando@firmamex.com",
         "sticker_page":0,
         "sticker_coordinates":{
            "lx":74.88173,
            "ly":312.32596,
            "ux":196.9875,
            "uy":373.37885
         },
         "sticker_type":"EMAIL",
         "sticker_image_type":"hash",
         "sticker_authorities":[
            {
               "authority_name":"Vinculada a Correo por token de Google"
            }
         ]
      }
   ],
   "document_url":"https://firmamex.com/pdf/6c46b879-42e1-4b3e-a1c4-dcf959178821/"
}


`}
        </Code>
    }
}

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Respuesta API</Subtitle>
            <div className="Content">
                <p>
                    Cada vez que subas un documento, Firmamex contestará con la información básica del documento que se dio de alta.
                </p>            
                <p>
                    La respuesta de Firmamex incluye los siguientes elementos:
                </p>
                <ul>
                    <li>
                        <strong>document_ticket:</strong> El ticket es el firmamexId. Te será útil cuando recibas 
                        notificaciones sobre las firmas del documento o para realizar acciones sobre el mismo.
                    </li>
                    <li><strong>document_flow:</strong> Indica si se inició o no un flujo de firmas para el documento.</li>
                    <li><strong>sticker_count:</strong> Indica la cantidad de sticker que se dieron de alta en el documento.</li>
                    <li><strong>stickers:</strong> Muestra los detalles de los stickers que se dieron de alta.</li>
                    <li><strong>document_url:</strong> URL del documento listo para ser firmado.</li>
                </ul>
                </div>
        </React.Fragment>
    )
}

export default { JS, html }