import React from 'react';
import Header from '../../shared/Header'
import Subtitle from '../../shared/Subtitle'
import Code from '../../shared/Code'

export function JS({codeLang}) {

    switch(codeLang) {
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        case 'http': return <Code lang="javascript">
{`

Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /developers/json

{
    url_doc: {
        url: 'https://www.dropbox.com/s/sxvgq1uhb4k3s4w/contrato.pdf?dl=0',
        name: 'contrato.pdf'
    },
    stickers: [{
        authority: 'SAT',
        stickerType: 'line',
        dataType: 'rfc',
        data: 'ARCX9012226P8',
        imageType: 'hash',
        email: 'jhon@gmail.com',
        page: 0,
        rect: {
            lx: 388,
            ly: 400,
            tx: 496,
            ty: 480
            } 
    }],
    qr: [{
        width:  150,
        height: 150,
        x: 0,
        y: 0,
        page: 1 
    }, {
        width:  120,
        height: 120,
        x: 200,
        y: 400,
        pages: [2,3,4]
    }, {
        width:  120,
        height: 120,
        x: 400,
        y: 600,
        pages: "all"
    }]  
}
`}
        </Code>
        default: return <Code lang="javascript">
{`

const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const response = await services.request({
    url_doc: {
        url: 'https://www.dropbox.com/s/sxvgq1uhb4k3s4w/contrato.pdf?dl=0',
        name: 'contrato.pdf'
    },
    stickers: [{
        authority: 'SAT',
        stickerType: 'line',
        dataType: 'rfc',
        data: 'ARCX9012226P8',
        imageType: 'hash',
        email: 'jhon@gmail.com',
        page: 0,
        rect: {
            lx: 388,
            ly: 400,
            tx: 496,
            ty: 480
            } 
    }],
    qr: [{
        width:  150,
        height: 150,
        x: 0,
        y: 0,
        page: 1 
    }, {
        width:  120,
        height: 120,
        x: 200,
        y: 400,
        pages: [2,3,4]
    }, {
        width:  120,
        height: 120,
        x: 400,
        y: 600,
        pages: "all"
    }]  
})
`}
        </Code>
    }
}

export function html() {
    return(
        <React.Fragment>
            <Header>Código QR</Header>
            <div className="Content">
            <p>
                Puedes agregar un código QR al documento, de tal manera que si alguien recibe el documento por cualquier medio, 
                incluso impreso, podrá validarlo usando la liga incrustada en el código QR.
            </p>
            <p>
                El elemento <code>qr</code> puede ser un objeto directamente o un arreglo de objetos con la siguiente estructura:
            </p>
            <ul>
                <li><strong>width:</strong> Ancho en pixeles del código.</li>
                <li><strong>height:</strong> Alto en pixeles del código.</li>
                <li><strong>x:</strong> Coordenada X de la posición del código (esquina superior izquierda del código).</li>
                <li><strong>y:</strong> Coordenada Y de la posición del código (esquina superior izquierda del código).</li>
                <li><strong>page:</strong> Página donde estará el código (empieza desde la página 1).</li>
            </ul>
            </div>
            <Subtitle>Repetir código QR</Subtitle>
            <div className="Content">
            <p>
                Para agregar el código QR en varias páginas <strong>en la misma posición</strong>, puedes cambiar el atributo "page" por "pages":
                <ul>
                    <li><strong>pages:</strong> Arreglo de páginas donde estará el código (empieza desde la página 1) o la palabra "all" para incluirlo en todas las páginas.</li> 
                </ul>
            </p>
            </div>
        </React.Fragment>
    )
}
export default { JS, html }