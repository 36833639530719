import React from 'react';
import Subtitle from '../../shared/Subtitle';
import Code from '../../shared/Code';

export function JS({codeLang}) {

    switch(codeLang) {
        case 'http': return <Code lang="javascript">
                {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type multipart/form-data

POST /api/nom151/stamp
const data = new FormData();
data.append('file', binaryFile, {
    filename: 'file'
})

{
    data
}

POST /api/nom151/validate
const data = new FormData();
data.append('file', binaryFile, {
    filename: 'file'
})

{
    data
}
                `}
                </Code>
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        default: return <Code lang="javascript">
{`
const fs = require('fs');
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

// Generación

const file = fs.readFileSync('./ejemplo.pdf'); 
const response = await services.nom151Stamp(file)

// Validación

const stampedFile = fs.readFileSync('./stampedFile.pdf');
const validationResult = await services.nom151Validate(stampedFile);

`}
        </Code>
    }
}


export function Response() {
    return <Code lang="javascript">
        {
            `
// Generación respuesta
{
    "status": "success" o "error",
    "document":"base64 del documento PDF",
    "timestampData":{
        "genTime": "fecha de generacion del sello",
        "hash": "hash que fue sellado",
        "status": "status contenido dentro de la respuesta de la TSA",
        "tsa": "nombre de la TSA que firmó el sello de tiempo"
    },
    "error": "si 'status' es 'error', contendra una descripción del error",
}
                        

// Validación respuesta
{
    "status": "success" o "error",
    "document": "base64 del documento PDF",
    "timestamp": "el timestamp en base64 que se extrajo del documento PDF",
    "timestampData": {
        "genTime": "fecha de generacion del sello",
        "hash": "hash que fue sellado",
        "status": "status contenido dentro de la respuesta de la TSA",
        "tsa": "nombre de la TSA que firmó el sello de tiempo"
    },
    "error": "si 'status' es 'error', contendra una descripción del error",
}
            `
        }
    </Code>
}

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Sello incrustado dentro de PDF</Subtitle>
            <div className="Content">
            <p>
                <strong>Generación</strong>
            </p>
            <p>
                Para generar el sello e incrustarlo dentro de un PDF, se utiliza la función nom151Stamp(), la cual 
                recibe el binario del documento como único argumento.
            </p>

            <br />

            <p>
                <strong>Validación</strong>
            </p>

            <p>
                Después de obtener un documento sellado, puedes validarlo en cualquier momento. Este proceso extrae 
                el sello del documento y lo valida, sólo acepta documentos con el sello de tiempo incrustado.
            </p>
           
            
            </div>
        </React.Fragment>
    )
}

export default { JS, html, Response }