import React from 'react';
import Subtitle from '../../shared/Subtitle'

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Utilizar un template previamente configurado en Firmamex</Subtitle>
            <div className="Content">
            <p>
                Con esta herramienta puedes generar tus propios templates y sólo mandar los datos que quieras inyectar en el documento.
                Firmamex inyectará los datos proporcionados al template y generará el documento.
            </p>
            <p>
                ¿Quieres conocer más acerca de los templates? <a href="/docs/documento/templates">Ver más</a>.
            </p>
            </div>
        </React.Fragment>
    )
}
export default { html }