import React from 'react';
import Subtitle from '../../shared/Subtitle';
import Code from '../../shared/Code';

export function JS({codeLang}) {

    switch(codeLang) {
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        case 'http': return <Code lang="javascript">
{`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /developers/json

// Agregar documento con Sticker al expediente
{ 
    url_doc: {
        "url":"https://www.dropbox.com/s/sxvgq1uhb4k3s4w/contrato.pdf?dl=0",
        "name":"contrato.pdf" 
    },
    document_set: 'id del expediente',
    stickers: [{
        "authority": "SAT", 
        "stickerType":"rect", 
        "dataType":"RFC", 
        "imageType":"stroke", 
        "data":"GOCF9002225A711", 
        "email": "firmante@dominio.com", 
        "page":0,
        "rect": {
            "lx": 330,
            "ly": 300,
            "tx": 530,
            "ty": 400
        } 
    }]
}
`}
        </Code>
        default: return <Code lang="javascript">
{`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");


// Crear expediente

const {documentSet} = await services.createDocumentSet('nombre para el expediente');

// Agregar documento con Sticker al expediente

await services.request({ 
    url_doc: {
        "url":"https://www.dropbox.com/s/sxvgq1uhb4k3s4w/contrato.pdf?dl=0",
        "name":"contrato.pdf" 
    },
    document_set: documentSet,
    stickers: [{
        "authority": "SAT", 
        "stickerType":"rect", 
        "dataType":"RFC", 
        "imageType":"stroke", 
        "data":"GOCF9002225A711", 
        "email": "firmante@dominio.com", 
        "page":0,
        "rect": {
            "lx": 330,
            "ly": 300,
            "tx": 530,
            "ty": 400
        } 
    }]
}

`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {`
{
    "document_ticket": "c6cb27b3-6211-4be8-b705-5dcac1624dcf",
    "sticker_count": 1,
    "stickers": [{
            "sticker_id": "vZPJ0v2xG",
            "sticker_index": 0,
            "sticker_email": "jhon@gmail.com",
            "sticker_data": "ARCX9012226P8",
            "sticker_page": 0,
            "sticker_coordinates": {
                "lx": 100.0,
                "ly": 100.0,
                "ux": 200.0,
                "uy": 200.0
            },
            "sticker_type": "RFC",
            "sticker_image_type": "hash",
            "sticker_authorities": [{
                    "authority_name": "SAT"
                }
            ]
        }
    ],
    "document_url": "https://firmamex.com/pdf/c6cb27b3-6211-4be8-b705-5dcac1624dcf/?child=true&inv=true"
}            
            `
        }
    </Code>
}

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Agregar documentos al expediente</Subtitle>
            <div className="Content">
            <p>
                Con el expediente creado, el siguiente paso es agregar documentos. Para agregar un documento 
                puedes utilizar las mismas funciones que están disponibles para generar un documento normalmente: 
                stickers, recuadros, qr, templates, etc. Pero agregando el identificador para el expediente.
            </p>
            <p>
                Notas importantes:
            </p>
            <ul>
                <li>
                    La única opción que no se puede utilizar en esta función es la de flujo (workflow), ya que el flujo 
                    se manejará a nivel expediente.
                </li>
                <li>
                    Cada firmante debe contener un dato de contacto. Esto significa que para las firmas por SAT, 
                    es obligatorio agregar el campo de correo (“email”)
                </li>
            </ul>
            </div>
            
        </React.Fragment>
    )
}

export default { JS, html, Response }