import React from 'react';
import Header from '../../shared/Header'
import Code from '../../shared/Code'

export function JS({codeLang}) {

    switch(codeLang) {
        case 'python': return <Code lang="javascript">
            {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
        </Code>
        case 'http': return <Code lang="javascript">
            {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /developers/json

{
    url_doc: {
        url: 'https://www.dropbox.com/s/sxvgq1uhb4k3s4w/contrato.pdf?dl=0',
        name: 'contrato.pdf'
    },
    owner: {
        email: 'owner@example.com'
    }
}
`}
        </Code>
        default: return <Code lang="javascript">
            {`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const response = await services.request({
    url_doc: {
        url: 'https://www.dropbox.com/s/sxvgq1uhb4k3s4w/contrato.pdf?dl=0',
        name: 'contrato.pdf'
    },
    owner: {
        email: 'owner@example.com'
    }
})
`}
        </Code>
    }
}


export function html() {
    return(
        <React.Fragment>
            <Header>Gestor</Header>
            <div className="Content">
                <p>El gestor(<code>owner</code>) es el dueño del documento</p>
                <p>
                    Cuando subes un documento, se asigna como gestor al usuario que se utilizó para generar
                    las credenciales del API. Entrando al repositorio de este
                    usuario es posible ver todos los documentos que se han subido con estas credenciales
                </p>
                <p>
                    Es posible cambiar este comportamiento y especificar el usuario en el que se
                    prefiere que se guarde el documento
                </p>
                <p>
                    Los requisitos son:
                    <ol>
                        <li>Que el usuario esté registrado como parte de la organización</li>
                        <li>Conocer el correo con el que se registró el usuario</li>
                    </ol>
                </p>
                <p>
                    El elemento <code>owner </code> es un objeto que contiene un único atributo:
                </p>
                <ul>
                    <li>
                        <strong>email:</strong> Correo con el que se registró al usuario que se asignará como gestor
                    </li>
                </ul>
                <h5>Mensajes de error</h5>
                <table class="table table-striped table-bordered">
                    <thead class="thead">
                    <tr>
                        <th scope="col">Mensaje</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Recomendación</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>invalid owner</td>
                        <td>No se encontró al usuario en la organización</td>
                        <td>Verifica los datos que se están enviando</td>
                    </tr>
                    <tr>
                        <td>can't verify user at this moment</td>
                        <td>No se puede verificar al usuario en este momento por un error en Firmamex</td>
                        <td>Reintenta más tarde</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}

export default {JS, html}
