import React from 'react';
import Header from '../../shared/Header';
import Code from '../../shared/Code';

export function JS({codeLang}) {

    switch(codeLang) {
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        default: return <Code lang="javascript">
{`

const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");


// Crear expediente

const {documentSet} = await services.createDocumentSet('nombre para el expediente');

// Agregar documento con Sticker al expediente

await services.request({ 
    url_doc: {
        "url":"https://www.dropbox.com/s/sxvgq1uhb4k3s4w/contrato.pdf?dl=0",
        "name":"contrato.pdf" 
    },
    documentSet: documentSet,
    stickers: [{
        "authority": "SAT", 
        "stickerType":"rect", 
        "dataType":"RFC", 
        "imageType":"stroke", 
        "data":"GOCF9002225A711", 
        "email": "firmante@dominio.com", 
        "page":0,
        "rect": {
            "lx": 330,
            "ly": 300,
            "tx": 530,
            "ty": 400
        } 
    }]
}

// Cerrar expediente

await services.closeDocumentSet({ 
    documentSet: documentSet,
    workflow: {
        remind_every: '1d',
        language: 'es',
        ordered: [
            'GOCF9002225A711',
        ]
    } 
})

`}
        </Code>
    }
}

export function html() {
    return (
        <React.Fragment>
            <Header>Sello de tiempo NOM 151</Header>
            <div className="Content">
            <p>
                El sello de tiempo se puede solicitar de dos maneras:
            </p>
            <ul>
                <p><li><strong>Incrustado dentro de un PDF:</strong> si se van a sellar documentos PDF recomendamos utilizar 
                este método. El sello queda incrustado dentro de la metadata del documento y después se agrega una firma 
                para que no se pueda modificar. Sirve como una manera de cerrar el documento y no se tienen que mantener 
                dos archivos.</li></p>
                <p><li><strong>Sello para cualquier tipo de archivo:</strong> La otra opción es generar el sello como un archivo 
                independiente. Esto permite que se puedan generar sellos para cualquier tipo de archivo, como fotos, videos, 
                docx, xlsx, etc.</li></p>
            </ul>
            <p>
                Además de los métodos para obtener el sello de tiempo, están disponibles los métodos para validar el sello 
                generado. La validación revisa que:
            </p>
            <ul>
                <p><li>El hash del documento corresponda al hash que se encuentra en el sello.</li></p>
                <p><li>Que el status del sello sea válido.</li></p>
                <p><li>Que la firma del sello sea válida.</li></p>
            </ul>
            </div>
            
        </React.Fragment>
    )
}

export default { JS, html }