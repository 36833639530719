import React from 'react';
import Header from '../../shared/Header'
import {Link} from 'react-router-dom'

export function html() {
    return(
        <React.Fragment>
            <Header>Templates</Header>
            <div className="Content">
            <p>
                Si estarás usando el mismo documento, sólo cambiando unos datos, 
                puedes darlo de alta como un template y sólo estar mandando 
                los datos que quieres que se inyecten. Firmamex inyectará los datos 
                y generará el documento PDF.
            </p>
            <p>
                Hay 2 formas diferentes de generar un template:
            </p>
            <ul>
                <Link to="/docs/documento/templates/html"><li>HTML</li></Link>
                <Link to="/docs/documento/templates/pdfmake"><li>pdfmake</li></Link>
            </ul>
            </div>
        </React.Fragment>
    )
}
export default {html}