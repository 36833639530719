import React from 'react';
import Header from '../shared/Header'

export function html() {
    return (
        <React.Fragment>
            <Header>Descargas</Header>
            <div className="Content">
                <div>
                    <p>
                        En las siguientes ligas podrás descargar el SDK para el lenguaje en el que estes desarrollando:
                </p>
                    <ul>
                        <li><a href='https://bitbucket.org/fimamexdevs/firmamex-node-ejemplos/downloads/' target="_blank" without rel="noopener noreferrer"><strong>Node</strong></a></li>
                        <li><a href='https://bitbucket.org/fimamexdevs/firmamex-php-ejemplos/downloads/' target="_blank" without rel="noopener noreferrer"><strong>PHP</strong></a></li>
                        <li><a href='https://bitbucket.org/fimamexdevs/firmamex-python-ejemplos/downloads/' target="_blank" without rel="noopener noreferrer"><strong>Python</strong></a></li>
                        <li><a href='https://bitbucket.org/fimamexdevs/firmamex-csharp-ejemplos/downloads/' target="_blank" without rel="noopener noreferrer"><strong>Net</strong></a></li>
                        <li><a href='https://bitbucket.org/fimamexdevs/firmamex-java-ejemplos/downloads/' target="_blank" without rel="noopener noreferrer"><strong>Java 8</strong></a></li>
                    </ul>
                </div>
            </div>
        </React.Fragment>
    )
}

export default { html }