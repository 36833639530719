import React from 'react';
import Subtitle from '../../shared/Subtitle'

export function html() {
    return(
        <React.Fragment>
            <Subtitle>Líneas de Firma</Subtitle>
            <div className="Content">
            <p>
                Si estás generando tu PDF a través de Microsoft Word, puedes colocar 
                una línea horizontal con un ALT text siguiendo las mismas reglas 
                que se utilizan para colocar recuadros de firma. Firmamex detectará 
                esta línea y colocará un sticker en su lugar.
            </p>
            </div>
        </React.Fragment>
    )
}
export default {html}