import React from 'react';
import Subtitle from '../../shared/Subtitle'
import Code from '../../shared/Code'

export function JS({codeLang}) {

    switch(codeLang) {
        case 'http': return <Code lang="javascript">
                {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
GET /api/certifiedEmail/{frmxId}/data
                `}
                </Code>
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        default: return <Code lang="javascript">
{`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const response = await services.getCertifiedEmailData('7481782e-391a-445d-995c-5e759290ad54');

`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {
            `
{
    // Arreglo de attachments con los archivos
    attachments: [{
        filename: 'contrato.pdf',
        data: 'base64...'
    }]
}
            `
        }
    </Code>
}

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Descargar información de correo certificado</Subtitle>
            <div className="Content">
                <p>
                    Puedes descargar la información de un correo certificado en cualquier momento. La información estará actualizada según el momento en que se descargue
                </p>
                </div>
          </React.Fragment>
    )
}

export default { JS, html, Response }