import React from 'react';
import Header from '../../shared/Header'
import {Link} from 'react-router-dom'

export function html() {
    return (
        <React.Fragment>
            <Header>Subir documento</Header>
            <div className="Content">
            <div>
                <p>
                    El uso más sencillo del SDK es enviar un documento para firma especificando un firmante.
                </p>
                <p>
                    Los métodos disponibles para enviar el documento a Firmamex son 3:                   
                </p>
                <ul>
                    <li>
                        <Link to="/docs/documento/subir/url">Utilizando la URL de un documento en línea</Link>
                    </li>
                    <li>
                        <Link to="/docs/documento/subir/base64">Enviando el documento en base64</Link>
                    </li>
                    <li>
                        <a href="/docs/documento/templates">Utilizar un template previamente configurado en Firmamex</a>
                    </li>
                </ul>
                <br />
                {/* <Subtitle>Nombre del documento</Subtitle>
                <p>
                    Cuando se utiliza una URL para enviar el documento, si el nombre del documento no es proporcionado en el objeto que hace referencia al documento que se enviará a Firmamex,
                    entonces el nombre del documento se extraerá del parámetro "filename" del header "Content-Disposition". De no encontrarse,
                    el documento se guardará con el nombre de "default.pdf"
                </p>
                <p>
                    Cuando se envía el documento en base64 el nombre del documento es obligatorio.
                </p> */}
            </div>
            </div>
        </React.Fragment>
    )
}

export default { html }