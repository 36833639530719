import React from 'react';
import Subtitle from '../../shared/Subtitle';
import Code from '../../shared/Code';

export function JS({codeLang}) {

    switch(codeLang) {
        case 'http': return <Code lang="javascript">
                {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json

// Generación
// Genera un hash256 de los datos para los que quieres 
// una estampilla de tiempo
POST /api/timestamp
{
    hash: hash.digest('hex'); 
}

// Validación
// Opción sello junto con archivo
POST /api/timestamp/validate
{ 
    file: binaryFile,
    timestamp: binaryTimestamp
}

// Opción sello con hash
POST /api/timestamp/validate
{ 
    hash: 'hexHash',
    timestamp: binaryTimestamp
}
                `}
                </Code>
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        default: return <Code lang="javascript">
{`
const fs = require('fs');
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

// Generación
// Genera un hash256 de los datos para los que quieres 
// una estampilla de tiempo

const hash = crypto.createHash('sha256'); 
hash.update('datos a estampillar');

// Manda el hash como un string hexadecimal

const response = await services.timestamp({
    hash: hash.digest('hex'); 
})

// Validación
// Opción sello junto con archivo

const validationResultFile = await services.timestampValidate({ 
    file: binaryFile,
    timestamp: binaryTimestamp
});

// Opción sello con hash

const validationResultFile = await services.timestampValidate({ 
    hash: 'hexHash',
    timestamp: binaryTimestamp
});

`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {
            `
// Generación respuesta
{
    "status": "success" o "error",
    "timestamp": "el timestamp en base64 generado para el archivo",
    "timestampData": {
        "genTime": "fecha de generacion del sello",
        "hash": "hash que fue sellado",
        "status": "status contenido dentro de la respuesta de la TSA",
        "tsa": "nombre de la TSA que firmó el sello de tiempo"
    },
    "error": "si 'status' es 'error', contendra una descripción del error",
}            

// Validación respuesta
{
    "status": "success" o "error",
    "timestampData": {
        "genTime": "fecha de generacion del sello",
        "hash": "hash que fue sellado",
        "status": "status contenido dentro de la respuesta de la TSA",
        "tsa": "nombre de la TSA que firmó el sello de tiempo"
    },
    "error": "si 'status' es 'error', contendra una descripción del error",
}
            `
        }
    </Code>
}

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Sello para cualquier tipo de archivo</Subtitle>
            <div className="Content">
            <p>
                <strong>Generación</strong>
            </p>
            <p>
                Para generar un sello para cualquier tipo de archivo, envía el hash 256 en hexadecimal del archivo.
            </p>

            <br />

            <p>
                <strong>Validación</strong>
            </p>

            <p>
                Después de obtener el sello, se puedes validar en cualquier momento enviando el sello junto con el archivo 
                original o el hash con el que se generó.
            </p>
           
            </div>
            
        </React.Fragment>
    )
}

export default { JS, html, Response }