import React from 'react';
import Header from '../shared/Header';
import flowChart from '../images/Firmamex-transparent.svg'

export default function Intro() {
    return (
        <React.Fragment>
            <Header>
                Introducción
            </Header>
            <div className="Content">
                <p>
                    El API de Firmamex permite utilizar todas las funciones que se encuentran en el portal de firma
                    para integrarlas en tus flujos según sea necesario.                        
                </p>
                <p>
                    Firmamex es una plataforma para gestionar el firmado de documentos de manera rápida y eficiente. En este portal para desarrolladores
                    encontrarás las herramientas necesarias para crear tu flujo de firmado de documentos.
                </p>
                <p>
                    El flujo de firmado trabaja de la siguiente manera: <br />
                </p>

                <ul>
                    <p><li>Envía el documento que quieres firmar al servidor de Firmamex para prepararlo para firma.</li></p>
                    <p><li>Notifica al firmante del documento esperando firma. Dependiendo de cómo sea configurado el documento enviado a Firmamex, hay diferentes formas de presentar el documento a los firmantes.</li></p>
                    <p><li>El firmante, dentro del portal de Firmamex, firma el documento y es redirigido a una URL personalizable.</li></p>
                    <p><li>Por medio de un webhook, Firmamex te notifica cuando el documento ha sido firmado.</li></p>
                </ul>
                <figure style={{textAlign: 'center'}}>
                    <img src={flowChart} alt="firmamex-flow" style={{width: '85%'}}/>
                </figure>
                <br />
                <br />
                <p>
                    3 * - El paso 3 en el flujo de Firmamex es opcional. Dependiendo del tipo de flujo que elijas 
                    vas a necesitar o no mandarle la URL a los firmantes.
                    <br />
                    <a href="/docs/documento/flujos">Ver más acerca de los Flujos.</a>
                </p>
            </div>
        </React.Fragment>
    )
}