import React from 'react';
import Header from '../shared/Header'
import Subtitle from '../shared/Subtitle'
import BlueSection from '../shared/BlueSection'
import Code from '../shared/Code'
import authHeader from '../images/Authorization-header.svg'

export function JS({ codeLang }) {
    switch (codeLang) {
        case 'http': {
            {
                return <Code lang="javascript">
                    {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
                    `}
                </Code>
            }
        }
        case 'python': {
            return <Code lang="javascript">
                {`
webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
                    `}
            </Code>
        }
        default: {
            return <Code lang="javascript">
                {`
String webId = "mBS73C5TdTjthjF0";
String apiKey = "521026f468b9dadc2419e476356934d0";

const services = new SignmageServices(webId, apiKey);
                                    `}
            </Code>
        }
    }
}


export function html() {
    return (
        <React.Fragment>
            <Header>Autenticación</Header>
            <div className="Content">

                <p>
                    Para comenzar a usar el API por favor contacta a contacto@firmamex.com y te asignaremos
                    tus credenciales de desarrollador
                </p>
                <p>
                    Credenciales de ejemplo:
                </p>
                <BlueSection>
                    <div>
                        WebId: mBS73C5TdTjthjF0
                    </div>
                    <div>
                        API Key: 521026f468b9dadc2419e476356934d0
                    </div>
                </BlueSection>
                <p>
                    El WebId es público y lo puedes guardar en cualquier lugar, pero el API Key equivale a tu
                    contraseña y le puede permitir a terceros solicitar documentos a tus usuarios.
                    Guárdalo en un lugar seguro.
                </p>
                <p>
                    Cada solicitud que realices debe llevar un HMAC generado con los parámetros de la solicitud y
                    el API Key. Para facilitar este proceso puedes utilizar el SDK que proporcionamos.
                </p>
            </div>
            <Subtitle>Autenticación por medio del SDK</Subtitle>
            <div className="Content">
                <p>
                    Para autenticarte por medio del SDK sólo es necesario pasarle tus credenciales de desarrollador
                    como se muestra en el panel lateral.
                </p>
                <p>
                    En un ambiente de producción, se recomienda que cargues tus credenciales desde un archivo externo
                    o desde una variable de ambiente.
                </p>
                <p>
                    Si el SDK no está implementado en el lenguaje de programación que utilizas, puedes llamar al API directamente
                    (ve "Usando el API directamente")
                </p>
                <br />
            </div>            
            <Subtitle>Autenticación vía API</Subtitle>
            <div className="Content">
                <p>
                    Si el SDK no está implementado en el lenguaje de programación que utilizas, puedes mandar llamar al API directamente.
                    El API es un grupo de endpoints que aceptan solicitudes JSON que contienen un encabezado de autenticación.
                </p>
                <p>
                    Abajo se encuentra una descripción básica de como trabaja, pero nos puedes contactar
                    en soporte@firmamex.com para cualquier duda que tengas.
                </p>
                <br />
                <p>
                    <strong>
                        Encabezado de Autenticación
                    </strong>
                </p>
                <p>
                    Cada solicitud al API debe tener un encabezado de autenticación que nos permite verificar
                    que eres un usuario autorizado y que la solicitud no ha sido cambiada por un tercero. El encabezado está formado de la
                    siguiente manera:
                </p>
                <ul>
                    <p><li><strong>Content type:</strong> El content type puede ser “<strong>application/json</strong>” o “<strong>multipart/form-data</strong>”.</li></p>
                    <p><li><strong>Identificador:</strong> el encabezado tiene que iniciar con “<strong>Authorization: signmage</strong>”.</li></p>
                    <p><li><strong>WebId:</strong> el WebId que te fue asignado como desarrollador.</li></p>
                    <p><li><strong>Separador:</strong> entre el WebId y el HMAC tiene que haber un símbolo <strong>":"</strong>.</li></p>
                    <p><li><strong>HMAC:</strong> 
                            <ul>
                                <p><li><strong>application/json:</strong> Un SHA-256 HMAC en base64 generado con los parámetros JSON de la solicitud
                                    y el API Key como el secreto. El JSON debe ser válido y sin espacios.</li></p>
                                <p><li><strong>multipart/form-data:</strong> Un SHA-256 HMAC en base64 generado con el base64Hash.</li></p>
                            </ul>
                        </li>
                    </p>
                    <p><li><strong>Content-SHA256:</strong> Un SHA-256 HMAC generado con el binario del archivo en la solicitud y luego convertirlo a base64.</li></p>
                </ul>
                <figure style={{ textAlign: 'center' }}>
                    <img src={authHeader} alt="Authorization Header" style={{ width: '90%' }} />
                </figure>
            </div>
        </React.Fragment >
    )
}

export default { JS, html }