
import React from 'react';
import Subtitle from '../../shared/Subtitle'
import Code from '../../shared/Code'

export function JS({codeLang}) {

    switch(codeLang) {
        case 'http': return <Code lang="javascript">
                {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type multipart/form-data
POST /api/pdf/pageData

const data = new FormData();
data.append('file', binario-del-archivo, {
    filename: 'nombre-del-archivo'
})
data.append('page', numero-de-pagina);
                `}
                </Code>
        case 'python': return <Code lang="javascript">
                {`
                `}
                </Code>
        default: return <Code lang="javascript">
{`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

try {
    const file = fs.readFileSync('file.pdf');
    const firstPage = await services.getPageData(file, 'first');
    const secondPage = await services.getPageData(file, '2');
    const lastPage = await services.getPageData(file, 'last');
} catch (e) {
    console.error({
        status: e.response.status,
        message: e.response.statusText,
        data: e.response.data
    })
}
`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {
            `
{
    "width": 594.96,
    "height": 842.04,
    "totalPages": 47
}
            `
        }
    </Code>
}

export function html() {
    return (
        <React.Fragment>            
            <Subtitle>Obtener tamaño de página PDF</Subtitle>
            <div className="Content">
                <p>
                    Los tamaños de las páginas en un archivo PDF pueden variar según el origen del documento, la configuración de la página, los márgenes, el escalado y otros factores. 
                </p>
                <p>
                    Si estás trabajando con documentos PDF y no conoces previamente el tamaño de la página, puedes utilizar este servicio para obtenerlo. Te proporcionará el ancho y el alto en puntos PDF.
                </p>
                <p>
                    Es importante tener en cuenta que un mismo PDF puede conter páginas de diferentes tamaños. Por lo tanto, debes especificar la página que te interesa como parámetro. Si la página no es relevante, o desconoces el total de páginas, puedes usar "first" o "last" para obtener el tamaño de la primera o la última página respectivamente
                </p>
            </div>
        </React.Fragment>
    )
}

export default { JS, html, Response }