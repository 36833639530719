import React from 'react';
import Header from '../../shared/Header'
import Code from '../../shared/Code'

export function JS({codeLang}) {

    switch(codeLang) {
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        case 'http': return <Code lang="javascript">
{`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /developers/json

{
    url_doc: {
        url: 'https://www.dropbox.com/s/sxvgq1uhb4k3s4w/contrato.pdf?dl=0',
        name: 'contrato.pdf'
    },
    stickers: [{
        authority: 'SAT',
        stickerType: 'line',
        dataType: 'rfc',
        data: 'ARCX9012226P8',
        imageType: 'hash',
        email: 'jhon@gmail.com',
        page: 0,
        rect: {
            lx: 388,
            ly: 400,
            tx: 496,
            ty: 480
            } 
    }],
    workflow: {
        subject: 'Asunto del correo de invitación',
        message: 'Mensaje personalizado en el correo de invitación',
        expiration_date: '12/11/2021',
        remind_every: '1d',
        language: 'es',
        attach_files: true,
        ordered: [
            {
                data: 'ARCX9012226P8'
            }
        ],
        watchers: [
            'correo@dominio.com'
        ]
    }   
}
`}
        </Code>
        default: return <Code lang="javascript">
{`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const response = await services.request({
    url_doc: {
        url: 'https://www.dropbox.com/s/sxvgq1uhb4k3s4w/contrato.pdf?dl=0',
        name: 'contrato.pdf'
    },
    stickers: [{
        authority: 'SAT',
        stickerType: 'line',
        dataType: 'rfc',
        data: 'ARCX9012226P8',
        imageType: 'hash',
        email: 'jhon@gmail.com',
        page: 0,
        rect: {
            lx: 388,
            ly: 400,
            tx: 496,
            ty: 480
            } 
    }],
    workflow: {
        subject: 'Asunto del correo de invitación',
        message: 'Mensaje personalizado en el correo de invitación',
        expiration_date: '12/11/2021',
        remind_every: '1d',
        language: 'es',
        attach_files: true,
        ordered: [
            {
                data: 'ARCX9012226P8'
            }
        ],
        watchers: [
            'correo@dominio.com'
        ]
    }   
})
`}
        </Code>
    }
}

export function html() {
    return(
        <React.Fragment>
            <Header>Flujos</Header>
            <div className="Content">
                <p>
                    Un flujo de firmas toma un documento con stickers, lo manda a todos los
                    firmantes y te notifica cuando todos lo han firmado.
                </p>
                <p>
                    Para poder utilizar el flujo de firmas deberás especificar el elemento <code>email </code>
                    cuando envíes el documento a Firmamex (este es obligatorio cuando se pretende utilizar
                    el flujo de firmas y la autoridad es SAT). También se deberá especificar el
                    elemento <code>workflow </code> al momento
                    de dar de alta el documento.
                </p>
                <p>
                    Para poder utilizar el flujo de firmas, el documento deberá
                    contener por lo menos un Sticker.
                </p>
                <p>
                    El elemento <code>workflow </code> es un objeto que contiene los siguientes elementos:
                </p>
                <ul>
                    <li>
                        <strong>expiration_date:</strong> Con el se puede especificar la fecha de expiración
                        del flujo de firma.
                    </li>
                    <li><strong>remind_every:</strong> Define que tan seguido se debe recordar a los firmantes.</li>
                    <li><strong>language:</strong> Lenguaje con el que se notificará a los firmantes.</li>
                    <li><strong>ordered:</strong> Orden en que las firmas serán solicitadas.</li>
                    <li><strong>watchers:</strong> Destinatarios no firmantes</li>
                    <li><strong>attach_files:</strong> Adjuntar documentos</li>
                    <li><strong>subject:</strong> Asunto</li>
                    <li><strong>message:</strong> Mensaje personalizado</li>
                    <li><strong>alert_all:</strong> Notificar a todos</li>
                    <li><strong>block_expired:</strong> Bloquear al expirar</li>
                    <li><strong>attach_timestamp:</strong> Enviar estampilla de tiempo en correo final</li>
                </ul>
                <br/>

                <p>
                    <strong>expiration_date</strong> (opcional)
                </p>
                <p>
                    Especifica la fecha <strong>en UTC</strong> de expiración del flujo de firma.
                    El flujo será cancelado si no se ha completado antes de esta fecha.
                    Por default su configuración es de 2 semanas a partir de la fecha en la que el flujo fue creado.
                </p>
                <p>
                    Es posible especificar hora y minuto de expiración, si no se especifican, se toman 12:00 como
                    default.
                </p>
                <p>
                    Formato de fecha: dd/mm/yyyy [HH:mm]
                </p>
                <p> Por ejemplo: <br/>
                    12/11/2021 <br/>
                    12/11/2021 18:30
                </p>
                <br/>

                <p>
                    <strong>remind_every</strong> (opcional)
                </p>
                <p>
                    Define que tan seguido se debe recordar a los firmantes que firmen
                    el documento.
                </p>
                <p>
                    Los valores permitidos son: none, 12h, 1d, 2d, 3d, 4d, 5d, 6d, 7d.
                </p>
                <p>
                    El valor de <strong>none</strong> es un caso especial, si se especifica este valor
                    el flujo enviará el correo de inicio y de fin, pero no enviará recordatorios
                </p>
                <p>
                    Por default se notificará a los firmantes 1d durante 2 semanas, después se dejará de notificar.
                </p>
                <br/>

                <p>
                    <strong>language</strong> (opcional)
                </p>
                <p>
                    Especifica el idioma en el que los firmantes recibirán la invitación a firmar
                    el documento en sus correos.
                </p>
                <p>
                    Por default este valor es 'es' (Español).
                </p>
                <p>
                    Los valores permitidos son: 'es' (Español) y 'en' (Inglés).
                </p>
                <br/>

                <p>
                    <strong>ordered</strong> (opcional)
                </p>
                <p>
                    Define el orden en el que las firmas serán solicitadas, si no se encuentra presente, significa que
                    el flujo es no ordenado.
                </p>
                <p>
                    Debe ser del mismo tamaño que el número de stickers en el documento.
                </p>
                <p>
                    Recibe el dato del firmante. Por ejemplo, si la autoridad para el Sticker es el SAT, este elemento
                    recibirá el RFC.
                </p>

                <br/>
                <p>
                    <strong>watchers</strong> (opcional)
                </p>
                <p>
                    Este campo es opcional, si se encuentra presente, estos correos recibiran una notificación cuando
                    inicie y otra
                    cuando termine el flujo, sin tener que ser parte de los firmantes
                </p>
                <br/>
                <p>
                    <strong>attach_files</strong> (opcional)
                </p>
                <p>
                    Indica si se deben adjuntar los documentos firmados en el correo de notificación final. Por default
                    es true
                </p>
                <br/>
                <p>
                    <strong>subject</strong> (opcional)
                </p>
                <p>
                    Permite personalizar el asunto de la invitación a firmar que se manda por correo. Por default es "Ha
                    sido invitado a firmar un documento"
                </p>
                <br/>
                <p>
                    <strong>message</strong> (opcional)
                </p>
                <p>
                    Permite inlcuir un mensaje personalizado dentro del correo de invitación
                </p>
                <br/>
                <p>
                    <strong>alert_all</strong> (opcional)
                </p>
                <p>
                    Si su valor es true, se notifica a todos los firmantes cuando se completa un flujo.
                    Si su valor es false, sólo se notifica al gestor del documento
                </p>
                <br/>
                <p>
                    <strong>block_expired</strong> (opcional)
                </p>
                <p>
                    Si esta opción es true, el documento se bloqueará y no permitirá firmarse al expirar el flujo. Por
                    default es false
                </p>
                <br/>
                <p>
                    <strong>attach_timestamp</strong> (opcional)
                </p>
                <p>
                    Si esta opción es true, el correo de notificación de completado incluirá un zip con el sello de
                    tiempo NOM151 que se incrustó en el documento
                </p>
            </div>
        </React.Fragment>
    )
}

export default {JS, html}
