import React from 'react';
import Header from '../../shared/Header';
import Code from '../../shared/Code';
import CodeSection from '../../shared/CodeSection'
import webhooksImg from '../../images/webhooks.png';
import { Table } from 'reactstrap'

export function JS({ codeLang }) {

    switch (codeLang) {
        case 'python': return <Code lang="javascript">
            {``}
        </Code>
        default: return <Code lang="javascript">
            {``}
        </Code>
    }
}

export function html() {
    return (
        <React.Fragment>
            <Header>Webhooks</Header>
            <div className="Content">
            <p>
                Puedes especificar un webhook para recibir notificaciones en tu servidor cuando un documento
                ha sido firmado, revisarlo, y continuar con tu proceso.
            </p>
            <p>
                Para especificar un webhook, inicia sesión en tu cuenta de desarrollador en https://firmamex.com/developers/login.
                La URL que proporciones debe aceptar solicitudes HTTP POST con un JSON Content Type (application/json).
            </p>
            <figure style={{ textAlign: 'center' }}>
                <img src={webhooksImg} alt="webhooks" width="85%" />
            </figure>
            <br />
            <p>
                Cada vez que se firme un documento que tu generaste, Firmamex hará un POST a tu webhook con la siguiente
                información:
            </p>
            <ul>
                <li>
                    <strong>notification_id:</strong> Es el ID de la notificación, puedes utilizar este ID para solicitar más
                    información sobre el documento.
                </li>
                <li><strong>document_title:</strong> El título del documento que fué firmado.</li>
                <li><strong>firmamex_id:</strong> Un ID único para el documento.</li>
                <li><strong>text:</strong> Descripción del evento.</li>
                <li><strong>notification_type:</strong> Tipo de notificación.</li>
                <li><strong>signer_email:</strong> El correo del firmante.</li>
                <li><strong>signer_fingerprint:</strong> Fingerprint del certificado del firmante.</li>
                <li><strong>signer_data:</strong> Información adicional del firmante.</li>
                <li><strong>meta:</strong> Algunas notificaciones contendrán este campo. Incluye información adicional sobre la notificación</li>
            </ul>
            <br />
            <p>
                <strong>notification_type</strong>
                <p>
                    Existen los siguientes tipos de notificaciones:
                </p>
                <ol>
                    <li>
                        <a href="#original_signed">original_signed</a>
                    </li>
                    <li>
                        <a href="#universal_signed">universal_signed</a>
                    </li>
                    <li>
                        <a href="#document_completed">document_completed</a>
                    </li>
                    <li>
                        <a href="#document_rejected">document_rejected</a>
                    </li>
                    <li>
                        <a href="#workflow_expired">workflow_expired</a>
                    </li>
                    <li>
                        <a href="#sms_error">sms_error</a>
                    </li>
                    <li>
                        <a href="#email_error">email_error</a>
                    </li>
                    <li>
                        <a href="#original_nom151_stamped">original_nom151_stamped</a>
                    </li>
                    <li>
                        <a href="#universal_nom151_stamped">universal_nom151_stamped</a>
                    </li>
                </ol>
            </p>            
            <ul>
                <li>
                    <strong id="original_signed">original_signed:</strong> Un usuario a firmado este documento.
                    <CodeSection lang="js" height="220px" label="Ejemplo">
                        {
                            `
                            {
                                "signer_data": {
                                    "id": 2000,
                                    "email": "fernando@firmamex.com",
                                    "fingerprint": "667483ad8b935fd6874477da9e822a60d6ab3b22",
                                    "authority": "Vinculada a Correo Electrónico por Liga",
                                    "name": "fernando@firmamex.com"
                                  },
                                  "signer_email": "fernando@firmamex.com",
                                  "signer_fingerprint": "667483ad8b935fd6874477da9e822a60d6ab3b22",
                                  "user": 1000,
                                  "signer": 2000,
                                  "notification_type": "original_signed",
                                  "notification_id": "2697",
                                  "document_title": "contrato.pdf",
                                  "firmamex_id": "04337396-e929-43ca-9b8a-ec958a44079d",
                                  "text": "Document 04337396-e929-43ca-9b8a-ec958a44079d.pdf just signed"
                              }
                            `
                        }
                    </CodeSection>
                </li>
                <li>
                    <strong id="universal_signed">universal_signed:</strong> La firma ha sido encapsulada.
                    <CodeSection lang="js" height="220px" label="Ejemplo">
                        {
                            `
                            {
                                "signer_data": {
                                    "id": 2000,
                                    "email": "fernando@firmamex.com",
                                    "fingerprint": "667483ad8b935fd6874477da9e822a60d6ab3b22",
                                    "authority": "Vinculada a Correo Electrónico por Liga",
                                    "name": "fernando@firmamex.com"
                                  },
                                  "signer_email": "fernando@firmamex.com",
                                  "signer_fingerprint": "667483ad8b935fd6874477da9e822a60d6ab3b22",
                                  "user": 1000,
                                  "signer": 2000,
                                  "notification_type": "universal_signed",
                                  "notification_id": "2700",
                                  "document_title": "contrato.pdf",
                                  "firmamex_id": "04337396-e929-43ca-9b8a-ec958a44079d",
                                  "text": "Document 04337396-e929-43ca-9b8a-ec958a44079d.pdf just signed"
                              }
                            `
                        }
                    </CodeSection>
                </li>
                <li>
                    <strong id="document_completed">document_completed:</strong> Se han firmado todos los stickers.
                    <CodeSection lang="js" height="220px" label="Ejemplo">
                        {
                            `
                            {
                                "notification_type": "document_completed",
                                "notification_id": "2698",
                                "document_title": "contrato.pdf",
                                "firmamex_id": "04337396-e929-43ca-9b8a-ec958a44079d",
                                "text": "Document 04337396-e929-43ca-9b8a-ec958a44079d.pdf has been completed"
                              }
                            `
                        }
                    </CodeSection>
                </li>
                <li>
                    <strong id="document_rejected">document_rejected:</strong> El firmante ha rechazado el documento.
                    <CodeSection lang="js" height="220px" label="Ejemplo">
                        {
                            `
                            {
                                "meta": {
                                  // correo, rfc o telefono del firmante que rechazo el documento
                                  "signer": "GOCF9002226A7",
                                  // razon especificada por el usuario por la que el documento fue rechazado (300 caracteres)
                                  "reason": "solicito cambio en ..."
                                },
                                "notification_type": "document_rejected",
                                "notification_id": "2693",
                                "document_title": "contrato.pdf",
                                "firmamex_id": "38a81807-be84-4ec2-8d39-33a1b5c1d78a"
                              }
                            `
                        }
                    </CodeSection>
                </li>
                <li>
                    <strong id="workflow_expired">workflow_expired:</strong> El flujo de firma ha expirado.
                    <CodeSection lang="js" height="220px" label="Ejemplo">
                        {
                            `
                            {
                                "notification_type": "workflow_expired",
                                "notification_id": "2693",
                                "document_title": "contrato.pdf",
                                "firmamex_id": "38a81807-be84-4ec2-8d39-33a1b5c1d78a"
                            }
                            `
                        }
                    </CodeSection>
                </li>
                <li>
                    <strong id="sms_error">sms_error:</strong> Sucedió un error al intentar enviar el SMS al firmante. El atributo "meta" contendrá
                    el número de teléfono y el tipo de error. Los tipos de errores pueden ser:
                    <Table>
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Descripción</th>
                                <th>Recomendación</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>400</td>
                                <td>Texto del SMS no incluido</td>
                                <td>Corregir solicitud</td>
                            </tr>
                            <tr>
                                <td>401</td>
                                <td>Número celular del SMS no incluido</td>
                                <td>Corregir solicitud</td>
                            </tr>
                            <tr>
                                <td>402</td>
                                <td>Tamaño de mensaje excedido</td>
                                <td>Corregir solicitud</td>
                            </tr>
                            <tr>
                                <td>403</td>
                                <td>Numero incorrecto</td>
                                <td>Corregir solicitud</td>
                            </tr>
                            <tr>
                                <td>450</td>
                                <td>Error al enviar el mensaje</td>
                                <td>Reintentar solicitud</td>
                            </tr>
                            <tr>
                                <td>500</td>
                                <td>Error interno de servidor</td>
                                <td>Contactar a Firmamex</td>
                            </tr>
                        </tbody>
                    </Table>
                    <CodeSection lang="js" height="220px" label="Ejemplo">
                        {
                            `
                            {
                                "meta": {
                                    "message": "Error interno de servidor",
                                    "code": "500"
                                  },
                                  "notification_type": "sms_error",
                                  "notification_id": "2702",
                                  "document_title": "contrato.pdf",
                                  "firmamex_id": "24ab99f2-eb3b-43c4-812d-2976490156e8"
                              }
                            `
                        }
                    </CodeSection>
                </li>
                <li>
                    <strong id="email_error">email_error:</strong> Sucedió un error al intentar enviar el correo al firmante. El atributo "meta" contendrá la información del error. Siempre tendrá al menos "message", "recipient" y "subject".
                    <CodeSection lang="js" height="220px" label="Ejemplo">
                        {
                            `
                        {
                            "meta": {
                                // descripcion del error proporcionada por el servidor destino
                                "description": "",
                                // mensaje de error proporcionado por el servidor destino
                                "message": "5.1.1 The email account that you tried to reach does not exist.",
                                // host del servidor destino
                                "mx-host": "aspmx.l.google.com",
                                // codigo de error smtp
                                "code": "550",
                                // direccion a la que se intento enviar el correo
                                "recipient": "fernando@firmamex.com",
                                // asunto del correo
                                "subject": "Ha sido invitado a firmar un documento Firmamex:kYb"
                              },
                            "notification_type": "email_error",
                            "notification_id": "2693",
                            "document_title": "contrato.pdf",
                            "firmamex_id": "38a81807-be84-4ec2-8d39-33a1b5c1d78a"
                        }
                            `
                        }
                    </CodeSection>
                </li>
            </ul>
            <br />
            <br/>
            <p>
                Además, si la cuenta de tu organización tiene activados los sellos NOM 151 de manera automática,
                se recibirán otras dos notificaciones adicionales.
            </p>
            <ul>
                <li><strong id="original_nom151_stamped">original_nom151_stamped:</strong> Se ha realizado el sello NOM 151 sobre el documento original.</li>
                <CodeSection lang="js" height="220px" label="Ejemplo">
                    {
                        `
                            {
                                "meta": {
                                    "hash": "017a330e54ba39ec30919b41d96bf9bc2a3b64c259158b1d88f1c59f8e21c2d3",
                                    "tsa": "L=MIGUEL HIDALGO,ST=CIUDAD DE MEXICO,C=MX,CN=CECOBAN-PSC-NOM151-1-01,OU=Thales TSS ESN:995D-C54C-D22E,O=CECOBAN SA DE CV",
                                    "policy": "2.16.484.101.10.316.2.3.1.2.1.1.1",
                                    "genTime": "2021-03-30T02:34:24.582Z"
                                  },
                                  "notification_type": "original_nom151_stamped",
                                  "notification_id": "2699",
                                  "document_title": "contrato.pdf",
                                  "firmamex_id": "04337396-e929-43ca-9b8a-ec958a44079d"
                              }
                            `
                    }
                </CodeSection>
                <li>
                    <strong id="universal_nom151_stamped">universal_nom151_stamped:</strong> Se ha realizado el sello NOM 151 sobre el documento universal.
                    <CodeSection lang="js" height="220px" label="Ejemplo">
                        {
                            `
                            {
                                "meta": {
                                    "hash": "017a330e54ba39ec30919b41d96bf9bc2a3b64c259158b1d88f1c59f8e21c2d3",
                                    "tsa": "L=MIGUEL HIDALGO,ST=CIUDAD DE MEXICO,C=MX,CN=CECOBAN-PSC-NOM151-1-01,OU=Thales TSS ESN:995D-C54C-D22E,O=CECOBAN SA DE CV",
                                    "policy": "2.16.484.101.10.316.2.3.1.2.1.1.1",
                                    "genTime": "2021-03-30T02:34:24.582Z"
                                  },
                                  "notification_type": "universal_nom151_stamped",
                                  "notification_id": "2701",
                                  "document_title": "contrato.pdf",
                                  "firmamex_id": "04337396-e929-43ca-9b8a-ec958a44079d"
                              }
                            `
                        }
                    </CodeSection>
                </li>
            </ul>
            </div>
        </React.Fragment>
    )
}

export default { JS, html }