import React from 'react';
import Subtitle from '../../shared/Subtitle'
import Code from '../../shared/Code'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

export function JS({ codeLang }) {

    switch (codeLang) {
        case 'http': return <Code lang="javascript">
                {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
GET /api/document?from={from}&to={to}&nextToken={nextToken}
                `}
                </Code>
        case 'python': return <Code lang="javascript">
            {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
        </Code>
        default: return <Code lang="javascript">
            {`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const from = new Date('2022-01-01').getTime();
const to = new Date('2022-01-12').getTime()

let result = await services.listDocuments(from, to);

while(result.nextToken) {
    result = await services.listDocuments(from, to, result.nextToken);
}


`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {
            `
{
    documents: [
        {
            // Indica si el documento se puede acceder
            active:true
            // Fecha de creacion del documento
            createdAt:1638320273000
            // Identificador del documento
            firmamexId:'0000-0000-0000-0000'
            // Nombre del documento
            originalName:'Contrato'
            // Total de firmas del documento
            signaturesCount:1
            // Estatus de su solicitud de firmas, puede ser uno de los siguientes:
            // NOSTICKERS: El documento no tiene stickers
            // STICKERS: El documento tiene por lo menos un sticker sin fimar
            // SITCKERS_SIGNED: El documento tiene todos los stickers firmados
            stickerStatus:'STICKERS'
        }
    ],
    // Para obtener siguiente pagina. Si no existen mas documentos, 
    // este atributo no existe
    nextToken: 'token'
}
            `
        }
    </Code>
}


export function html() {
    return (
        <React.Fragment>
            <Subtitle>Obtener documentos</Subtitle>
            <div className="Content">
                <p>
                    Puedes obtener todos los documentos que hayas subido a Firmamex buscándolos por rango de fechas. Es importante tener en cuenta los siguientes puntos:
                </p>
                <ul>
                    <li>
                        Las fechas se esperan como el número de <b>milisegundos</b> que han pasado desde el 1 de enero de 1970 a la fecha deseada.
                        Por ejemplo, el resultado de la función <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTime" target="_blank" without rel="noopener noreferrer">getTime<FontAwesomeIcon icon={faExternalLinkAlt} /></a> de JavaScript
                    </li>
                    <li>
                        El API devolverá los resultados en orden <strong>ascendente</strong> de fecha.
                    </li>
                    <li>
                        El API devolverá los resultados en <strong>páginas de 200 elementos</strong>. Si tu rango de fechas contiene más de 200 elementos, en la respuesta se incluirá un <code>nextToken</code> que podrías enviar en una solicitud adicional para obtener los siguientes elementos.
                    </li>
                </ul>

                <p>Los parámetros que se solicitan son:</p>

                <ul>
                    <li><strong>from:</strong> Fecha de inicio de búsqueda.</li>
                    <li><strong>to:</strong> Fecha de término de búsqueda.</li>
                    <li><strong>nextToken:</strong> Si se proporciona, indica el siguiente conjunto de elementos que se deben obtener.</li>
                </ul>

            </div>
        </React.Fragment>
    )
}

export default { JS, html, Response }