import React from 'react';
import Subtitle from '../../shared/Subtitle';
import Code from '../../shared/Code';

export function JS({codeLang}) {

    switch(codeLang) {
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        case 'http': return <Code lang="javascript">
{`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /api/documentset/close

{ 
    documentSet: documentSet,
    workflow: {
        remind_every: '1d',
        language: 'es',
        ordered: [
            'GOCF9002225A711',
        ]
    } 
}

`}
        </Code>
        default: return <Code lang="javascript">
{`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");


// Crear expediente

const {documentSet} = await services.createDocumentSet('nombre para el expediente');

// Agregar documento con Sticker al expediente

await services.request({ 
    url_doc: {
        "url":"https://www.dropbox.com/s/sxvgq1uhb4k3s4w/contrato.pdf?dl=0",
        "name":"contrato.pdf" 
    },
    documentSet: documentSet,
    stickers: [{
        "authority": "SAT", 
        "stickerType":"rect", 
        "dataType":"RFC", 
        "imageType":"stroke", 
        "data":"GOCF9002225A711", 
        "email": "firmante@dominio.com", 
        "page":0,
        "rect": {
            "lx": 330,
            "ly": 300,
            "tx": 530,
            "ty": 400
        } 
    }]
}

// Cerrar expediente

await services.closeDocumentSet({ 
    documentSet: documentSet,
    workflow: {
        remind_every: '1d',
        language: 'es',
        ordered: [
            'GOCF9002225A711',
        ]
    } 
})

`}
        </Code>
    }
}


export function Response() {
    return <Code lang="javascript">
        {
            `
// id del expediente
{
    "document_set":"7c320b07-5b71-4ef4-8b8e-7db8cb1d8f21"
}
            `
        }
    </Code>
}

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Cerrar expediente</Subtitle>
            <div className="Content">
            <p>
                El último paso es cerrar el expediente, un expediente cerrado ya no permite que se agreguen más documentos.
            </p>
            <p>
                Para cerrar el expediente, es necesario que envíes el identificador del expediente y las opciones del flujo 
                de firmas (workflow). Estas opciones son las mismas que se utilizan para crear un flujo de firmas de un documento 
                (ver sección de <a href="/docs/documento/flujos">Flujos</a>), con la limitante de que tiene que ser un flujo ordenado.
            </p>
            </div>
            
        </React.Fragment>
    )
}

export default { JS, html, Response }