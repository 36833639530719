import React from 'react';
import Subtitle from '../../shared/Subtitle';
import Code from '../../shared/Code';

export function JS({codeLang}) {

    switch(codeLang) {
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
data = services.request({
"url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        case 'http': return <Code lang="javascript">
            {`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
GET /api/documentset/{id}
            `}
        </Code>
        default: return <Code lang="javascript">
{`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const documentSetData = await services.getDocumentSet(documentSet);

`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {`
{ 
    "uuid":"9v3d6a47-e4e8-48ef-b9b3-7af45c8207dn", 
    "status":"CLOSED",
    "documents":[
        "4a3d6a47-e4e8-48ef-b9b3-7af45c8207de",
        "5d08eabe-ecd0-46ad-9dbd-5d54098aca70"
    ],
    "workflow": {
        // fecha en milisegundos transcurridos desde ECMASCript epoch
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#the_ecmascript_epoch_and_timestamps        
        "lastNotification": 1665001495000,
        "expirationDate": 1666211094000,
        "currentSigner": "firmante@dominio.com"
    }
    "signers":[
        "GOCF9002226A511", 
        "firmante@dominio.com", 
        ...
    ] 
}
        `}
    </Code>
}

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Consultar expediente</Subtitle>
            <div className="Content">
            <p>
                Puedes consultar el estatus del expediente en cualquier momento para verificar los documentos 
                y firmantes que han sido agregados.
            </p>
            <p>
                Si el expediente se encuentra en proceso, la respuesta contendrá un atributo extra "workflow", con el firmante en turno, 
                la fecha de última notificación y la fecha de expiración
            </p>
            </div>
        </React.Fragment>
    )
}

export default { JS, html, Response }