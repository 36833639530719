import React from 'react';
import Subtitle from '../../shared/Subtitle';

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Confirmación de Webhook</Subtitle>
            <div className="Content">
            <p>
                Firmamex espera recibir un código HTTP 200 como respuesta a la notificación. Si no lo recibe, 
                asume que hay un problema y se comportará de la siguiente manera:
            </p>
            <ul>
                <p><li><strong>URL inválida:</strong> Si el webhook es una URL inválida, no volverá aintentar.</li></p>
                <p><li><strong>Si no se obtiene respuesta de una URL válida:</strong> Firmamex volverá a intentar 
                    utilizando exponential backoff. Esto quiere decir que programará su ejecución de acuerdo a la
                    siguiente fórmula:</li></p>
                    <br />
                <p>
                    10 segundos + min((2 ^ intentos), 600) + Random(0-1)ms
                </p>
                <p>
                    Por lo que el tiempo irá incrementando entre cada intento hasta llegar a un máximo de 600 
                    segundos (10 minutos) y 30 intentos.
                </p>
            </ul>
            <br />
            <br />
            </div>
        </React.Fragment>
    )
}

export default { html }