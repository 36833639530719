import React from 'react';
import Subtitle from '../../shared/Subtitle';
import Code from '../../shared/Code';

export function JS({codeLang}) {

    switch(codeLang) {
        case 'python': return <Code lang="javascript">
                {`
from signmage import SignmageServices

webid = "mBS73C5TdTjthjF0"
apikey = "521026f468b9dadc2419e476356934d0"

services = SignmageServices(webid, apikey)
const data = services.request({
    "url_doc":"https://firmamex.com/pdf/simple/default"
})
                `}
                </Code>
        case 'http': return <Code lang="javascript">
{`
Authorization signmage mBS73C5TdTjthjF0:HMAC
Content-Type application/json
POST /api/documentset

{
    "name":"nombre para el expediente"
}


`}
        </Code>
        default: return <Code lang="javascript">
{`
const FirmamexServices = require('./firmamex');
const services = FirmamexServices('mBS73C5TdTjthjF0', "521026f468b9dadc2419e476356934d0");

const {documentSet} = await services.createDocumentSet('nombre para el expediente');

`}
        </Code>
    }
}

export function Response() {
    return <Code lang="javascript">
        {
            `
// id del expediente
{
    "document_set":"7c320b07-5b71-4ef4-8b8e-7db8cb1d8f21"
}
            `
        }
    </Code>
}

export function html() {
    return (
        <React.Fragment>
            <Subtitle>Crear expediente</Subtitle>
            <div className="Content">
            <p>
                Esta función crea un expediente vacío. Recibe como parámetro un nombre para el expediente 
                y responde con un identificador único.
            </p>
            </div>
            
        </React.Fragment>
    )
}

export default { JS, html, Response }