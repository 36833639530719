import React from 'react';
import Header from '../../shared/Header';
import {Link} from 'react-router-dom';


export function html() {
    return (
        <React.Fragment>
            <Header>Expedientes</Header>
            <div className="Content">
            <p>
                Un expediente de documentos es un conjunto de documentos que se manejan en un sólo flujo. 
                Se envía una sola invitación a cada persona involucrada y desde esa invitación puede ver y 
                firmar los documentos que contenga el expediente.
            </p>
            <p>
                El manejo del expediente se maneja en 3 pasos:
            </p>
            <ul>
                <li><Link to='/docs/documento/expedientes/crear'>Crear el expediente</Link></li>
                <li><Link to='/docs/documento/expedientes/agregar'>Agregar documentos al expediente</Link></li>
                <li><Link to='/docs/documento/expedientes/cerrar'>Cerrar expediente</Link></li>
            </ul>
            </div>
        </React.Fragment>
    )
}

export default { html }